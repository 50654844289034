import { Turbo } from "@hotwired/turbo-rails";
Turbo.session.drive = true;
import "./controllers";

import "trix"
import "@rails/actiontext"

var currentURL = window.location.href;
if (currentURL.includes("admin")) {
    require("./packs/admin");
} else {
    require("./links");
}

import "./packs/sign_in"

document.addEventListener('turbo:load', function () {
  let overlayElement = document.querySelector('.ask-admin-popup-overlay');
  let closeButton = document.querySelector('.close-ask-popup');
  let openButton = document.querySelector('.ask-btn');

  if (openButton) {
    function openPopup() {
      overlayElement.style.visibility = 'visible';
      document.body.style.overflow = 'hidden';
    }

    function closePopup() {
      overlayElement.style.visibility = 'hidden';
      document.body.style.overflow = '';
    }

    closeButton.addEventListener('click', closePopup);

    overlayElement.addEventListener('click', function (event) {
      if (event.target === overlayElement) {
        closePopup();
      }
    });

    openButton.addEventListener('click', openPopup);
  }
});
